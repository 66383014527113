import projectionAnnualised from "./projection-annualised"
export default {
  intro: {
    header: "Bienvenue {name}!",
    "subheader-1": "à votre voyage vers l'autonomie solaire",
    "subheader-2": "Commençons la découverte de votre potentiel solaire",
    "your-presentation": "Votre présentation",
    "is-loading": "est en cours de chargement.",
    print: {
      header: "Étude technico-financière sur 25 ans du photovoltaïque",
      title: "Le projet solaire de",
      name: "{name}.",
      power: "Proposition",
    },
  },

  installer_profile: {
    header: "{installerName}",
    "paragraph-1":
      "Ci-dessous retrouvez une présentation de notre établissement.",
    "we-are": "Qui nous sommes",
    phone: "Téléphone :  ",
    email: "Email :  ",
    employees: "Employés",
    departments: "Départements",
    "learn-more": "En savoir plus sur notre établissement",
    headquarter: "Siège social",
    "founded-date": "Date de fondation",
  },

  "virtual-battery-not-available":
    "batterie virtuelle pas disponible pour cette étude",

  "electricity-bill": {
    intervals: {
      annual: "Annuel",
      monthly: "Mensuel",
    },
    header: "Ma facture d’énergie dans la durée",
    "paragraph-1":
      "Ne rien faire, et ainsi ne pas agir sur sa consommation d’électricité, implique un coût réel … en hausse continue.",
    "paragraph-2": "",
    "fourniture-consommation": {
      title: "Consommation",
      description:
        "La consommation correspond à la quantité d'électricité que vous avez utilisée en kWh, avec ou sans plages horaires.",
    },
    subscription: {
      title: "Abonnement",
      description:
        "L'abonnement représente les coûts fixes associés à la livraison de l'électricité, indépendamment de la quantité consommée d'électricité.",
    },
    "taxes-locals-tva": {
      title: "Taxes locales et TVA",
      description:
        "Le CSPE et autres taxes s'appliquent sur votre consommation de kWh. La TVA (Taxe sur la Valeur Ajoutée) s'ajoute aux montants de votre consommation et autres taxes.",
    },
    "virtual-batterie-subscription": {
      title: "Abonnement à la batterie virtuelle",
      description:
        "L'abonnement au fournisseur de batterie virtuelle représente le coût fixe pour bénéficier du stockage virtuel.",
    },

    "slide-assumptions":
      "Hypothèse de calcul : montant de la facture calculé à partir des conditions tarifaires actuelles de votre contrat d'énergie.",

    "see-your-bill": "votre facture électrique aujourd'hui",
    "see-your-price": "vos prochaines factures électriques",
    "is-business": "Pas segment C5",
    "is-not-business": "Segment C5",
    "power-subscribed": "Puissance souscrite",
    "hour-slots": "Plages horaires",
    "price-per-kWh": "Prix du kWh",
    "line-items": {
      header: "Facture d'électricité",
      "variable-tariff-expenditure": {
        title: "Consommation",
        "off-peak": "Consommation en heures creuses",
        peak: "Consommation en heures pleines",
        base: "Consommation en heures base",
      },
      "fixed-tariff-expenditure": "Abonnement",
      taxes: {
        title: "Toutes taxes",
        "local-taxes": "Taxes locales",
        "cta-taxes": "Taxe CTA",
        "fixed-tariff-taxes": "TVA 5,5%",
        "variable-tariff-taxes": "TVA 20%",
      },
      total: "Somme TTC",
    },
  },
  "electricity-bill-comparison": {
    name: "Mes économies avec stockage virtuel",
    description:
      "Choisir la batterie virtuelle c'est faire le maximum d'économies sur facture. Toute votre production d'électricité solaire est autoconsommée. Vous réduisez davantage vos montants des factures électriques en maîtrisant mieux comment votre surplus est utilisé.",
    "sub-heading": "Réduction sur ma facture électrique",
  },

  advantages: {
    header: "Mon gain économique",
    subtitle:
      "Retrouvez ci-dessous les gains économiques qu’engendre le passage au solaire.",
    "advantage-from-solar": "Avantage de l'énergie solaire",
    "net-spending": "Dépenses nettes",
    "expected-price-per-kw": "Prix HT attendu du kWh",
    "feed-in-tariff": "Tarif d’achat",
    summary: {
      heading: "Résumé",
      "electricity-bill-without-solar": "Facture électrique avant solaire",
      "electricity-bill-with-solar": "Facture électrique après solaire",
      "electricity-bill-savings": "Économies sur la facture d'électricité",
      "surplus-sold": "Vente du surplus",
    },
  },

  "projection-table": projectionAnnualised,

  "cashflow-report": {
    header: "Revenus vente de production",
    table: {
      groups: {
        revenues: "Part Vendue",
        liabilities: "Dépenses",
        results: "Résultats",
      },
      columns: {
        year: "Année",
        production: "Production annuelle",
        "production-exported-to-grid": "Part Vendue",
        "feed-in-tariff": "Tarif d’achat par kWh",
        "before-tax-revenue": "Chiffre d'affaires",
        maintenance: "Charges",
        "net-cash-flow": "Bilan Economique",
        "cumulative-net-cash-flow": "Cumul trésorerie",
      },
    },
  },

  financing: {
    header: "Financement",
    "paragraph-1":
      "Explorer les différents amortissements en fonction du financement.",
    "paragraph-2":
      "Valoriser votre patrimoine en investissant dans votre épargne solaire. ",
    "paragraph-3":
      "Privilégiez le prêt bancaire afin d’éviter l’investissement en amont.",
    "disclaimer-1":
      "Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager. élance ne propose pas de contrats de crédit pour le financement photovoltaïque. élance s’engage uniquement à indiquer, à titre gratuit, un établissement bancaire à une personne intéressée à la conclusion d’une opération de banque (selon l’article R. 519-2 du Code monétaire et financier).", //this is credit financing disclaimer

    "financing-options": {
      title: "Solution de financement",
      loan: "Avec crédit",
      "all-equity": "Au comptant",
      ppa: "PPA",
      "roof-lease": "Location de toiture",
    },
    summary: {
      header: "Résumé de rentabilité",
      "return-on-investment-25-year": "Retour sur investissement",
      "return-on-investment-1-year": "Retour sur investissement par an",
      npv: "Valeur actuelle nette (VAN)",
      "monthly-repayment": "Mensualité",
      "payback-period": "Période d'amortissement",
    },
    inputs: {
      header: "Paramètres du crédit",
      "equity-share": "Apport en fonds propres",
      "debt-share": "Montant du crédit",
      "loan-duration": "Durée du crédit (en années)",
      "interest-rate": "TAEG fixe",
    },
    "headline-number": {
      title: "Dépenses nets sur la durée du prêt :",
      unit: "€ par mois",
      "info-dialogue":
        "Votre effort d'épargne sera la différence entre votre mensualité et vos économies mensuelles sur la facture. Si votre gain économique est supérieur à votre mensualité, votre investissement est autofinancé.",
    },
    "slide-assumptions":
      "Hypothèse de calcul : estimation du prêt bancaire sur 7 ans, avec frais de dossier de 1%, taux de 3%, assurance 0,50%.",

    "image-header": "",
    chart: {
      header: "votre gain économique",
      label: "",
      "y-axis": "€",
    },
    disclaimer: {
      title:
        "Un crédit engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.",
      description:
        "Projetsolaire rappelle que le service ne propose pas de contrats de prêt pour l’autofinancement du Système Photovoltaïque et de ça Pose aux Clients. Projetsolaire ne présente pas, ne propose pas ni aide à la conclusion des opérations de banque ou des services de paiement ou à effectuer tous travaux et conseils préparatoires à leur réalisation. Projetsolaire ne fournit en aucune forme des prestations d’autofinancement, ni d’offres de crédit affecté, ni aucune mise en relation avec des organismes de crédit pour une telle prestation. Projetsolaire s’engage uniquement à indiquer, à titre gratuit, un établissement bancaire à une personne intéressée à la conclusion d’une opération de banque ou d’un service de paiement en lui remettant des documents à caractère publicitaire, et de transmettre à un établissement bancaire les coordonnées d’une personne intéressée à la conclusion d’une opération de banque ou de services de paiement, selon l’article R. 519-2 du Code monétaire et financier. Le Parties sont informées qu’projetsolaire ne perçoit aucune rémunération en contrepartie du service d’intermédiation qu’il fournit à l’établissement bancaire. Il est précisé que le Professionnel reconnaît et accepte les responsabilités et risques d’un crédit et le devoir de vigilance. Un crédit engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.",
      "text-link": "Pour en savoir plus, veuillez lire attentivement nos",
      link: "Conditions Générales de Services.",
    },
  },

  "physical-battery-intro": {
    heading: "Le stockage avec les batteries",
    "paragraph-1": "Profitez enfin de l’indépendance énergétique !",
    "paragraph-2":
      "Une batterie stocke votre surplus d’électricité solaire, et devient automatiquement la source d'énergie de votre foyer lorsque le réseau est en panne. Contrairement aux générateurs, les batteries continuent d’alimenter votre éclairage et de faire fonctionner vos appareils, sans bruit.",
    "paragraph-3":
      "Comment ça marche : Pendant la journée, le surplus d’électricité solaire de vos panneaux solaires est automatiquement stocké. Les batteries sont en charge. Lorsqu’il fait mauvais temps ou durant la nuit, vous puisez l’électricité depuis votre système de stockage. Les batteries se déchargent.",
    summary: {
      "capacity-nominal": "Capacité nominale",
      "capacity-usable": "Capacité utilisable",
      "max-charge": "Charge maximale",
      "max-discharge": "Décharge  maximale",
    },
  },
  "physical-battery-comparison": {
    header: "Mon autoconsommation avec batteries",
    "average-local-consumption-rate": "Pourcentage d’autonomie",
    "average-electricity-bill-savings": "Économies sur la facture",
    "with-battery": "Avec le solaire et batteries",
    "without-battery": "Avec le solaire sans batteries",
    "hide-battery": "Cacher stockage physique",
    displays: {
      "local-consumption": "Autonomie",
      "electricity-bill-savings": "Vos économies",
    },
  },
  "physical-battery-netting": {
    "physical-battery-surplus-production": {
      header: "Mon autoconsommation avec système de stockage",
      "paragraph-1":
        "Le principe de la batterie de stockage est d'autoconsommer votre excédent de production d'électricité solaire. Quand vous produisez plus d'électricité que ce dont vous avez besoin (par exemple, en août), votre surplus de production d'énergie solaire est d'abord dirigé vers la batterie plutôt que d'être exporté au réseau.",
      "paragraph-2":
        "Le système de stockage des batteries se charge jusqu'à ce que la capacité d'énergie soit atteinte. La capacité d'énergie (en kWh) varie selon les puissances des modèles de batteries. Vous exportez ensuite l'excédent de production d'électricité.",
      "disclaimer-1": "",
    },

    "physical-battery-charge": {
      header: "Charge de votre batterie physique",
      "paragraph-1":
        "Votre surplus de production est automatiquement chargé dans votre système de stockage. Vous n'exportez plus d'électricité au réseau électrique. Votre système de batterie(s) est ainsi en charge aux heures où votre production est supérieure à votre consommation.",
      "paragraph-2":
        "Le système de stockage est en charge jusqu'à ce qu'il soit rempli. Les batteries sont chargées. Le surplus de production solaire est ensuite exporté sur le réseau électrique pour être vendu ou valorisé.",
      "disclaimer-1": "",
    },

    "physical-battery-discharge": {
      header: "Vos contrats d'électricité avec votre batterie physique",
      "paragraph-1":
        "Lorsque votre production est supérieure à la consommation et que votre batterie physique soit entièrement chargée, vous exportez automatiquement votre électricité sur le réseau électrique. Quand votre batterie physique est pleine, vous valorisez votre surplus de production, égal à la différence entre la production et la consommation.",
      "paragraph-2":
        "En optant pour le stockage physique vous ne changez pas de fournisseur d'énergie. Vous pouvez aussi obtenir un contrat d'achat de votre électricité et la vendre. Vous pouvez ainsi cumuler le stockage physique et la vente du surplus - ou bien le stockage physique avec le stockage virtuel.",
      "disclaimer-1": "",
    },

    "physical-battery-excess-consumption": {
      header: "Durée de la capacité stocké",
      "paragraph-1":
        "Le système de stockage est chargé avec tout le surplus que vous ne pouvez pas utiliser immédiatement. Quand votre production est supérieure à votre consommation, la journée par exemple, vous stockez pour plus tard, le soir par exemple. Votre surplus de production est restitué entièrement et donc autoconsommé.",
      "paragraph-2":
        "Vous pouvez garder l'électricité dans votre système de stockage définitivement. Vous n'achetez de l'électricité depuis le réseau électrique uniquement quand votre batterie physique est vide. Attention ! Une fois que la capacité d'énergie est chargée, vous ne pouvez plus bénéficier du stockage sur l'excédent de production.",
      "disclaimer-1": "",
    },

    "physical-battery-cumulative-charge": {
      header: "Durée de la capacité stocké",
      "paragraph-1":
        "En même temps, la batterie virtuelle stocke tout le surplus que vous ne pouvez pas utiliser immédiatement et sans limite de durée. Quand votre production est supérieure à votre consommation, l'été par exemple, vous stocké pour plus tard, l'hiver par exemple. Votre surplus de production est restitué entièrement et donc autoconsommé.",
      "paragraph-2":
        "Vous pouvez garder l'électricité dans votre batterie virtuelle définitivement. Vous n'achetez de l'électricité depuis le réseau électrique uniquement quand votre batterie virtuelle est vide. Attention ! En changeant de fournisseur d'énergie, vous perdiez toute la capacité stocké auprès du fournisseur de batterie virtuelle.",
      "disclaimer-1": "",
    },
  },

  "virtual-battery-intro": {
    heading: "Batterie virtuelle : fonctionnement",
    "paragraph-1":
      "Une batterie sans investissement supplémentaire et avec stockage illimité. La batterie virtuelle valorise votre production d’électricité solaire pour qu’elle couvre d’avantage votre consommation d’électricité.",
    "paragraph-2":
      "Par beau temps, vous auto-consommez votre production d’électricité. Votre compteur affiche zéro. Pendant la journée, votre surplus d’électricité solaire est comptabilisé par votre compteur et stocké. Lorsqu’il fait mauvais temps ou durant la nuit, votre électricité ainsi comptabilisée et stockée virtuellement est restituée.",
    "paragraph-3":
      "Toute votre production d'électricité solaire est ainsi autoconsommée ! Rien n'est vendu. Votre taux d'autoconsommation est à 100%.",
    "subscribed-capacity": "Capacité maximale de stockage virtuel est de X kWh",
  },

  "virtual-battery-netting": {
    "virtual-battery-surplus-production": {
      header: "Mon autoconsommation avec stockage virtuel",
      "paragraph-1":
        "Le principe de la batterie virtuelle est de stocker votre surplus de production d'électricité solaire. Quand vous produisez plus d'électricité que dont vous avez besoin (par exemple, en août), votre surplus est entièrement exporté au réseau et comptabilisé sur votre batterie virtuelle.",
      "paragraph-2":
        "Selon les fournisseurs de batterie virtuelle, vous pouvez stocker votre électricité solaire sans limite dans votre batterie virtuelle, en fonction de votre contrat et puissance d'abonnement.",
      "disclaimer-1": "",
    },

    "virtual-battery-charge": {
      header: "Charge de votre batterie virtuelle",
      "paragraph-1":
        "Votre surplus de production est automatiquement exporté gratuitement au fournisseur de batterie virtuelle. La charge est comptabilisé via votre compteur Linky.",
      "paragraph-2":
        "Votre batterie virtuelle est ainsi en charge aux heures ou votre production est supérieure à votre consommation. Attention : selon votre contrat en dépassant le seuil de votre batterie virtuelle, le surplus est cédé gratuitement.",
      "disclaimer-1": "",
    },

    "virtual-battery-discharge": {
      header: "Décharge de votre batterie virtuelle",
      "paragraph-1":
        "Votre surplus de production est ensuite automatiquement restitué quand votre consommation est supérieure à votre production. Vous soutirez l'électricité de votre batterie virtuelle tant qu'elle est chargée sans importer et payer depuis le réseau électrique.",
      "paragraph-2":
        "La batterie virtuelle se décharge, permettant d'autoconsommer toute votre production d'électricité solaire ; même sans soleil. Votre compteur Linky affiche zéro.",
      "disclaimer-1": "",
    },

    "virtual-battery-excess-consumption": {
      header: "Changement de fournisseur d'énergie",
      "paragraph-1":
        "Lorsque votre consommation est supérieure à la production et que votre batterie virtuelle se vide entièrement, vous achetez automatiquement votre électricité chez votre fournisseur de batterie virtuelle, selon sa grille tarifaire.",
      "paragraph-2":
        "En optant pour le stockage virtuel vous changez de fournisseur d'énergie - votre fournisseur de batterie virtuelle vous facture la différence entre votre consommation et votre production équivalant à vos importations restantes du réseau électrique quand votre batterie virtuelle est vide.",
      "disclaimer-1": "",
    },

    "virtual-battery-cumulative-charge": {
      header: "Durée de la capacité stocké",
      "paragraph-1":
        "En même temps, la batterie virtuelle stocke tout le surplus que vous ne pouvez pas utiliser immédiatement et sans limite de durée. Quand votre production est supérieure à votre consommation, l'été par exemple, vous stocké pour plus tard, l'hiver par exemple. Votre surplus de production est restitué entièrement et donc autoconsommé.",
      "paragraph-2":
        "Vous pouvez garder l'électricité dans votre batterie virtuelle définitivement. Vous n'achetez de l'électricité depuis le réseau électrique uniquement quand votre batterie virtuelle est vide. Attention ! En changeant de fournisseur d'énergie, vous perdiez toute la capacité stocké auprès du fournisseur de batterie virtuelle.",
      "disclaimer-1": "",
    },
  },

  "virtual-battery-return-on-investment-comparison": {
    heading: "Ma rentabilité avec stockage virtuel",
    "paragraph-1":
      "Retrouvez ci-dessous le comparatif de rentabilité entre la vente du surplus et la batterie virtuelle.",
    roi: "RSI sur 25 ans",
    npv: "Valeur actuelle nette",
    "payback-period": "Amortissement ",
  },

  "virtual-battery-local-consumption-comparison": {
    heading: "Mon autonomie en stockage virtuel",
    "paragraph-1":
      "Le principal avantage de la batterie virtuelle est qu'elle vous permet de valoriser pleinement votre surplus à votre prix du kWh même quand vous n'êtes pas chez vous.",
    "paragraph-2":
      "Ainsi, quand vous partez en vacances votre surplus vous attends pour la rentrée, et si vous produissez beaucoup en été vous mettez l'électricité de côté pour l'hiver.",
    "paragraph-3":
      "En autoconsommant plutôt que vendant votre surplus de production, votre électricité solaire génère davantage d'économies sur votre facture d'électricité. Avec la batterie virtuelle vous gardez le contrôle sur la totalité de votre énergie solaire.",
  },

  "virtual-battery-savings-comparison": {
    header: "votre facture",
    "paragraph-1":
      "Avec la batterie virtuelle vous réalisez des économies beaucoup plus importantes qu'avec EDF OA. À la place d'être vendu sur le réseau électrique, votre surplus réduit votre consommation.",
    "paragraph-2":
      "Nous prenons en compte tous les changements sur votre facture électrique, notamment par rapport aux taxes et au coût supplémentaire de la batterie virtuelle, notamment l'abonnement, mais aussi les frais cachés au cas par cas (les impôts sur l'inejction de l'électricité et/ou le coût de l'installation).",
    "paragraph-3":
      "La batterie virtuelle vous confère donc également un avantage dans la durée, si les prix de l'électricité grimpent.",
    "disclaimer-1": "",
    savings: "économies",
    bill: "facture électrique",
    "future-savings": "ma facture dans 5 ans",

    expenses: {
      expenses: "composant",
      values: "coût (€)",
      "subscriptions-enedis-and-virtual-battery": "Abonnement",
      "subscription-enedis": "Abonnement enedis",
      "subscription-virtual-battery": "Abonnement batterie",
      consumption: "Consomation électrique",
      taxes: "Impôts",
      "taxes-local": "Taxes locales",
      "taxes-vat": "Taxes TVA",
      "taxes-injection": "Taxes injection",
    },
  },

  "virtual-battery-surplus-comparison": {
    header: "comment comparer la batterie virtuelle et EDF OA",
    "paragraph-1":
      "Pour comparer la batterie virtuelle et EDF OA, nous comparons la valeur du surplus injecté sur le réseau.",
    "disclaimer-1": "",
    "with-edf":
      "Chez EDF OA votre surplus est vendu sur le réseau électrique pour 10c€.",
    "with-urban-solar":
      "Chez Urban Solar Energy votre surplus n'est pas vendu ; il fait un aller-retour avec la batterie virtuelle. Votre surplus remplace donc vos achats d'électricité, mais il faut prendre en compte les taxes.",
    "math-comparison":
      "Voici mathématiquement comment vous comparez la valeur :",
  },

  climate: {
    header: "Impact sur la durée de vie",
    "paragraph-1":
      "Retrouvez ici diverses statistiques de l'impact de votre démarche solaire sur l’environnement",
    "co2-collected": "CO2 en tonnes collectées",

    stats: {
      "energy-payback": {
        stat: "{value} ans",
        emphasis: "Amortissement en énergie",
      },
      "energy-per-panel": {
        emphasis: "Energie",
        rest: "par panneau",
      },
      "pollution-in-france": {
        emphasis: "Pollution",
        rest: "en France",
      },
      "average-pollution": {
        emphasis: "Pollution moyenne",
        rest: "",
      },
    },
    accords: {
      title:
        "Les objectifs de développement durable de l'Organisation des Nations Unies (ONU) grâce à l’énergie solaire",
    },
  },

  lidar: {
    header: "Surface disponible en 3D",
    "paragraph-1":
      "La technologie satellitaire du Lidar permet de cartographier le relief en 3D. Le nuage de points de données provient de jeu de données de la NASA. Les orientations et inclinaisons et autres obstructions sont prises en compte au 0,10m du bien avec la télédétection Lidar du relief. Les surfaces et obstructions sont modélisées en 3D pour identifier les vrais masques d’ombrages. ",
    "paragraph-2":
      "La télédétection Lidar permet de déterminer de manière précise, grâce aux données d’ensoleillement des observatoires géographiques et météorologiques Français et Européen,  l'irradiance solaire au m². Le dimensionnement du système photovoltaïque est ainsi optimisé par rapport au gisement solaire de la surface disponible.",
    "mobile-paragraph-1":
      "La télédétection satellitaire Lidar permet de cartographier le relief en 3D. Les orientations, inclinaisons et obstructions sont prises en compte au 0,10m. Les surfaces et obstructions sont modélisées en 3D pour identifier les vrais masques d'ombrages.",
    "mobile-paragraph-2":
      "La télédétection Lidar permet de déterminer de manière précise l'irradiance solaire au m², grâce aux données d'ensoleillement des observatoires géographiques et météorologiques Français et Européens.",
    checkbox: {
      "box-1": "Cartographie au 0,10m du relief de l’environnement proche",
      "box-2": "Orientations et inclinaisons des différentes surfaces",
      "box-3": "Arbres et structures environnantes prises en compte ",
      "box-4": "Obstructions, évacuations et pans de toiture incluses",
    },
  },

  irradiance: {
    title: "Mon ensoleillement",
    "paragraph-1":
      "Le productible photovoltaïque est déterminé en fonction du nombre et de la puissance (en kWc) des panneaux solaires, ainsi que de leurs rendements énergétiques (en kWh/kWc) au m². Elle repose sur un moteur puissant qui nécessite uniquement la localisation géographique concernée - un point GPS.",
    "paragraph-2":
      "La modélisation du productible photovoltaïque en kWh au m² est calculée à partir de l'ensoleillement du site, depuis les données historiques de la station météorologique la plus proche.",
    "paragraph-3":
      "La précision technique au kWh/m² permet une véritable optimisation de l'emplacement des panneaux solaires pour un rendement maximal au propriétaire.",
    "mobile-paragraph-1":
      "Le productible photovoltaïque est déterminé à partir du nombre et puissance (en kWc) des panneaux solaires, ainsi que de leur rendement énergétique (en kWh/kWc) au m².",
    "mobile-paragraph-2":
      "La modélisation du productible en kWh au m² est calculée à partir de l'ensoleillement du site, depuis les données historiques de la station météorologique la plus proche.",
    "mobile-paragraph-3":
      "La précision technique permet d’optimiser l'emplacement des panneaux solaires, pour un rendement maximal.",

    "statistic-1": "Rendement énergétique moyen du m²",

    "statistic-2": "Irradiance moyenne du m²",
  },

  "consumption-connection": {
    manual: {
      header: "l’analyse horaire de votre consommation d’électricité",
      "paragraph-1":
        "L’étude d’autonomie électrique se fonde sur votre historique réel de consommation électricité (en kWh).",
      "paragraph-2":
        "Le comptage de votre consommation électrique est effectué par Enedis. Enedis est le gestionnaire du réseau public de distribution d’électricité, qui garantit l’acheminement de l’électricité. Enedis comptabilise votre consommation électrique au travers du compteur communicant. C’est le fameux compteur Linky. ",
      "paragraph-3":
        "Étant une entité référencée auprès d’Enedis, élance utilise votre courbe de charge horaire afin d’estimer votre autoconsommation électrique avec précision.",
    },
    general: {
      header: "L’analyse de votre facture électrique",
      "paragraph-1":
        "L’étude d’autonomie électrique se fonde sur votre historique réel de consommation électricité (en kWh).",
      "paragraph-2":
        "Le comptage de votre consommation électrique est effectué par Enedis. Enedis est le gestionnaire du réseau public de distribution d’électricité, qui garantit l’acheminement de l’électricité. Enedis comptabilise votre consommation électrique au travers du compteur communicant. C’est le fameux compteur Linky.",
      "paragraph-3":
        "élance utilise les données techniques présentes sur votre facture électrique afin d’estimer votre autoconsommation électrique avec précision.",
    },
  },

  consumption: {
    title: "Votre historique de consommation",
    "explore-more": "Explorer votre courbe de charge heure par heure",
    "paragraph-1":
      "Une analyse technique, en kWh, de votre consommation d’électricité réelle est réalisée à partir de votre historique.",
    "annual-consumption": "Consommation annuelle",

    "headline-number": {
      title: "Votre consommation d'électricité :",
      unit: "kWh par an",
      "info-dialogue":
        "Votre historique de consommation d'électricité chez vous, à partir de votre courbe de charge horaire.",
    },
    "slide-assumptions":
      "Hypothèse de calcul : consommation d'électricité extrapolée à partir du jeu de données transmis par vos soins.",
    "explore-data": "Explorez votre historique de consommation :",
    "see-winter-day": "voir une journée en hiver",
    "see-summer-day": "voir une journée en été",

    winter: "Une journée en hiver",
    summer: "Une journée en été",
    "consumption-greater-production":
      "Votre consommation historique est supérieure à votre production d'électricité.",
    "production-greater-consumption":
      "Votre production d'électricité est supérieure à votre consommation historique.",
    "consumption-greater-by":
      "En ce jour d'hiver, vous consommerez {deficit} kWh plus que votre production.",
    "production-greater-by":
      "En ce jour d'été, vous produirez {surplus} kWh plus que votre consommation.",
    "consumption-purchased":
      "Vous continuerez acheter cette électricité sur le réseau électrique au travers de votre fournisseur d'énergie.",
    "surplus-sold":
      "Votre surplus sera injecté sur le réseau, revendu auprès d'EDF pour 0,10 € le kWh.",

    chart: {
      //interval graph
      header: "votre consommation d'électricité",
      label: "",
      "y-axis": "Mesures d'électricité (kWh)",
    },
  },
  netting: {
    header: "Mon autoconsommation électrique",
    "header-subtitle":
      "L’autoconsommation électrique est le fait de consommer sa propre production d’électricité solaire. Le surplus est la part de la production non-autoconsommée sur place.",
    consumption: {
      title: "Consommation",
      "paragraph-1":
        "Votre consommation correspond à vos importation d'électricité du réseau électrique. Une analyse technique, en kWh, de votre consommation d’électricité est effectuée à partir de votre contrat d’énergie et de votre historique de consommation. La précision technique des données se fonde sur des mesures horaires.",
    },
    production: {
      title: "Production",
      "paragraph-1":
        "Votre production d'électricité solaire correspond à la production estimée d'électricité solaire. Une analyse technique, en kWh, de votre productible photovoltaïque est effectuée à partir du dimensionnement du système solaire sur votre gisement solaire. La précision technique des données se fonde sur des mesures horaires.",
    },
    consumption_split: {
      title: "Répartition de la consommation",
      "paragraph-1":
        "L'autoconsommation électrique correspond à la production d'électricité solaire consommée sur place. Votre consommation importée du réseau électrique est remplacée par la production d'électricité solaire. Cependant le soleil ne brille pas tout le temps. Vous continuerez d’acheter de l’électricité à votre fournisseur d'énergie la nuit.",
    },
    production_split: {
      title: "Répartition de la production",
      "paragraph-1":
        "Inversement, le surplus de votre production non-autoconsommée sur place est réinjecté au réseau électrique. La part excédentaire de votre production, le surplus, est ainsi automatiquement exportée sur le réseau électrique.",
    },

    "paragraph-4":
      "Cette graphique vous montre la partie de votre électricité solaire qui sera utilisée chez vous et celle injectée sur le réseau électrique.",

    "headline-number": {
      title: "Pourcentage d'autonomie électrique :",
      unit: "%",
      "info-dialogue":
        "Votre pourcentage d’autonomie correspond à la part de votre consommation d’électricité qui provient de l’électricité solaire.",
    },
    "slide-assumptions":
      "Hypothèse de calcul : taux d'autoproduction calculé à partir de l'historique de votre consommation d'électricité.",
    "return-to-consumption": "nouvelle consommation du réseau",
    "follow-production": "l'utilisation de mon auto-production",

    chart: {
      header: "votre autoconsommation",
      label: "",
      "y-axis": "Mesures d'électricité (kWh)",
    },
  },

  "system-guideline": {
    header: "Le cahier des charges",
    "paragraph-1":
      "Le dimensionnement de votre installation photovoltaïque est ainsi optimisé. Voici la fiche technique de votre système solaire.",
    "paragraph-2": "Puissance du système solaire : ",
    "paragraph-3": "Nombre de panneaux solaires : ",
    "paragraph-4": "Technologie des cellules :",
  },

  sizing: {
    heading: "Mon système solaire",
    "paragraph-1":
      "Le dimensionnement du système photovoltaïque, le calepinage, est optimisé par rapport à l'évaluation de l’ensoleillement au kWh/m².",
    "paragraph-2": "Nombre de panneaux",
    "paragraph-3": "%{numberPanels} panneaux solaires",
    "paragraph-4": "Production d'énergie par an",
    "paragraph-5": "%{annualProduction} kWh par an",
    "paragraph-6": "Puissance photovoltaïque",
    "paragraph-7": "%{systemSize} Wc",
  },

  "electricity-bill-savings": {
    header: "Mes économies sur la facture électrique",
    "paragraph-1":
      "Une partie de votre consommation d’électricité est ainsi couverte par votre l’électricité solaire : ce sont vos économies sur votre facture électrique.",

    "yearly-view": "Annuel",
    "monthly-view": "Mensuel",
    annual: {
      "without-solar": {
        title: "Cette année",
        description:
          "Nous estimons que votre facture électrique cette année sera de <h3>%{electricityBill} € sans votre énergie solaire</h3>",
      },
      "with-solar": {
        title: "L'année prochaine",
        description:
          "L’année prochaine nous estimons que votre facture électrique sera de <h3>%{electricityBill} € avec votre énergie solaire</h3>",
      },
      savings: {
        title: "Vos économies",
        description:
          "<h3>%{percentageReductionInBill} % d’économie sur votre facture électrique</h3>",
      },
    },
  },

  "payback-period": {
    header: "la période d’amortissement",
    "paragraph-1":
      "Votre investissement sera donc amorti par trois mécanismes différents.",
    "paragraph-2": "Vos économies sur votre facture électrique :",
    "paragraph-3": "%{lifetimeSavings} € sur 25 ans.",
    "paragraph-4": "La vente du surplus :",
    "paragraph-5": "%{lifetimeRevenues} € sur 20 ans.",
    "paragraph-6":
      "En prenant aussi en compte la prime à l’autoconsommation, votre investissement sera :",
    "paragraph-7": "amorti en %{paybackPeriod} ans.",
  },

  "return-on-investment": {
    header: "la rentabilité économique",
    "paragraph-1":
      "Les flux financiers de votre système photovoltaïque sont donc additionné puis actualisé sur 25 ans. ",
    "paragraph-2":
      "Votre gain économique cumulé sur la durée de vie, net de l'investissement, est donc de :",
    "paragraph-3": "%{lifetimeSavings} € sur %{period} ans",
    "paragraph-4": "amorti en %{paybackPeriod} ans",
    "paragraph-5": "Soit un retour sur investissement de :",
    "paragraph-6": "%{roi} % par an",
    index: {
      title: "Retour sur investissement",
      "paragraph-1":
        "Une partie de votre consommation d’électricité est ainsi couverte par votre l’électricité solaire : ce sont vos économies sur votre facture électrique.",
      "paragraph-2":
        "Valoriser votre patrimoine en investissant dans votre épargne solaire. La plus value aujourd’hui du bien vaut la valeur des flux de trésorerie sur la durée de vie du projet solaire.",
      stats: {
        "annualised-roi": "Retour sur investissement annuel",
        "full-period-roi": "Retour sur investissement sur 25 ans",
        "payback-period": "Période d'amortissement",
      },
    },
  },

  "local-consumption": {
    header: "Mon autonomie solaire",
    "learn-more":
      "Explorez les données horaires en autoconsommation électrique",
    "paragraph-1":
      "Votre pourcentage d’autonomie correspond à la part de votre consommation qui provient de l’électricité solaire.",
    "paragraph-2":
      "Cependant le soleil ne brille pas tout le temps. Vous continuerez d’acheter de l’électricité à votre fournisseur d'énergie la nuit.",
    "paragraph-3": "Pourcentage d’autonomie électrique",
    "paragraph-4": "{apPercentage} %",
  },

  subsidies: {
    heading: "Aides de l’État",
    title: "Prime à l’autoconsommation",
    with: {
      subtitle:
        "L’État encourage tous les particuliers à investir dans l'énergie solaire chez soi. La prime à l’autoconsommation est la seule aide de l’État pour le solaire sans conditions de revenus.",
      "description-1":
        "Autrefois versée sur 5 ans, la prime à l’autoconsommation est désormais versée en une seule fois par EDF Obligation Achat (OA), dispositif du service public.",
      "description-2":
        "La prime est versée au travers du contrat EDF OA, en même temps que le montant de la première année de vente de surplus au tarif d'achat fixé par l'État.",
      stats: {
        title:
          "Le montant de la prime dépend de la puissance photovoltaïque en kWc.",
        "stat-1": "Montant par kWc",
        "stat-2": "Montant de la prime ",
        "stat-3": "Tarif d’achat",
      },
      "feed-in-tariff-secondary-rate":
        "Au-delà du plafond annuel de {hours} heures de production, le surplus est vendu au tarif d'achat de {tariff} / kWh hors TVA.",
      conditions:
        "Attention ! Il est obligatoire que l’installateur photovoltaïque soit labélisé RGE (Reconnu garant de l’environnement) avec le certificat à jour.        ",
      "citation-regulation":
        "Arrêté du 22 décembre 2023 modifiant l'arrêté du 6 octobre 2021 fixant les conditions d'achat de l'électricité produite par les installations implantées sur bâtiment, hangar ou ombrière utilisant l'énergie solaire photovoltaïque, d'une puissance crête installée inférieure ou égale à 500 kilowatts telles que visées au 3° de l'article D. 314-15 du code de l'énergie et situées en métropole continentale.",
      criteria: {
        title: "Les critères d’éligibilité sont :",
        "criteria-1":
          "Soit installée sur le toit, avec le plan du système photovoltaïque parallèle au plan des éléments de couverture qui l’entoure.",
        "criteria-2":
          "Soit installée sur un toit plat (avec une pente inferieure a 5%)",
        "criteria-3":
          "Doit remplir une fonction d’allège, de bardage, de brise-soleil, de garde-corps, de pergolas, de mur-rideau ou d’ombrière.",
        "criteria-4":
          "Les installations photovoltaïques au sol ne donnent pas droit à la prime à l'autoconsommation.",
        "criteria-5":
          "L’installation photovoltaïque doit assurer l’étanchéité du bâtiment.",
      },
    },
    without: {
      "ground-mount":
        "Le contrat EDF OA n'est pas disponible pour les installations photovoltaïques au sol selon l’arrêté tarifaire en vigueur.",
      "virtual-battery":
        "En batterie virtuelle aucune vente de surplus et donc aucun contrat EDF OA au travers duquel la vente de surplus est faite.",
      "full-resale":
        "Aucune prime à l'autoconsommation avec le contrat EDF OA pour la vente en totalité.",
      stats: {
        "stat-1": "Manque à gagner de prime",
      },
    },
    chart: {
      header: "les aides d’État",
      label: "les aides d’État",
    },
    chip: {
      title: "Éligible aux subventions ?",
    },
  },
  compare: {
    "bread-crumb": {
      presentation: "Étude",
      compare: "Comparer propositions",
    },
    heading: {
      title: "Comparez et choisissez !",
      description:
        "Retrouvez ci-dessous un comparatif des différentes propositions disponibles.",
    },
    table: {
      button: "Explorer",
      "per-year": "par an",
      options: "Propositions",
      pricing: {
        title: "Investissement",
        guidance: "Montant estimé",
        "all-inclusive": "Tout compris",
        signable: "Signable",
        "technical-visit": "Visite technique",
        "down-payment": "Acompte",
        "tax-rate": "TVA",
      },
      "technical-information": {
        title: "Informations techniques",
        size: "Puissance photovoltaïque",
        "consumption-regime": "Régimes de raccordement",
        "panel-technology": "Technologie des cellules",
        panels: "Panneaux solaires",
        inverters: "Onduleur(s)",
        "annual-production": "Productible photovoltaïque",
        "energy-captured-yield": "Rendement énergétique au m²",
        irradiance: "Ensoleillement au m²",
        surface: "Supérficie",
      },
      returns: {
        title: "Rentabilité",
        "annualised-roi": "Retour sur investissement par an",
        "full-period-roi": "Retour sur investissement sur 25 ans",
        "payback-period": "Période d'amortissement",
        npv: "Valeur actuelle nette",
      },
      "electricity-bill": {
        title: "Facture d'électricité",
        "reduction-in-electricity-bill":
          "Réduction de la facture d'électricité",
        "savings-on-current-bill": "Économies sur la facture actuelle",
        "electricity-bill-with-solar": "Facture d'électricité après solaire",
      },
      autoconsumption: {
        title: "Autoconsommation électrique",
        ap: "Pourcentage d'autonomie",
        "self-consumption": "Autoconsommation",
        surplus: "Vente du surplus",
        "consumption-from-grid": "Consommation du réseau",
        "ap-off-peak": "AP en heures creuses (%)",
        "ap-peak": "AP en heures pleines (%)",
      },
      revenues: {
        title: "Vente de production solaire",
        "feed-in-tariff": "Tarif d'achat",
        gross: "Revenu de vente",
        subsidy: "Prime à l'autoconsommation",
      },
      financing: {
        title: "Financement",
        enabled: "Activée",
        method: "Solution de financement",
        details: "Détails du financement",
        "details-descriptions": {
          LOAN: "Crédit d'un montant de {principal} € pour une durée de {duration} ans au taux de {interestRate} %.",
          PPA: "An offer of {perKwhRate} per kWh of your production with an annual escalation rate of {escalationRate} %",
          LEASE: "{}",
        },
      },
      "virtual-battery": {
        title: "Stockage virtuel",
        enabled: "Activée",
        contract: "Contrat",
      },
      climate: {
        title: "Impact sur le climat",
      },
    },
  },

  summary: {
    header: "Résumé de la proposition",
    "paragraph-1": "Retrouvez ci-dessous la synthèse de votre proposition : ",
    name: "Intitulé de la proposition",
    description: "Description de la proposition",
    panels: "Panneaux solaires",
    inverters: "Onduleurs",
    financials: {
      production: "Production",
      ap: "Pourcentage d'autonomie",
      ROI: "Retour sur investissement",
      "ROI-annual": "Retour sur investissement par an",
      "payback-period": "Période d’amortissement",
      "cost-estimate": "Estimatif de l'investissement",
      subsidies: "Aides de l’État",
      percentageReductionInBill: "Économies sur facture",
      surplusProductionRevenue: "Vente de production",
      npv: "Valeur actuelle nette",
      averageYearlyBillWithSolar: "Facture électrique après solaire",
      averageSavingsOnBill: "Économies mensuelles sur facture électrique",
    },
  },

  conclusion: {
    header: "Merci",
    subtitle: "d'avoir exploré votre potentiel solaire.",
    button1: "Obtenir devis",
    button2: "Comparer propositions",
    "paragraph-1":
      "Si toutefois quelques questions persistent, n'hésitez pas à nous contacter pour en discuter.",
  },
  categories: {
    energy: "Énergie",
    returns: "Rendement",
    savings: "Économies",
    "virtual-battery": "Stockage virtuel",
    "physical-battery": "Stockage",
    climate: "Climat",
    design: "Présentation",
    "company-values": "Company values",
  },

  production: {
    title: "Mon productible photovoltaïque",
    paragraph:
      "La production d’électricité solaire est calculée heure par heure, jour par jour - du lever au coucher du soleil - en prenant en compte la saisonnalité, sur 365 jours. Une courbe horaire en kWh du productible photovoltaïque est simulée à partir du système photovoltaïque.",
    "explore-more":
      "Explorer votre productible photovoltaïque heure par heure.",
    "annual-production": "Production d'énergie par an",
  },
  "provider-comparison": {
    heading: "Valoriser son surplus",
    "paragraph-1":
      "Comparatif du prix du kwh exporté entre le tarif d'achat de l'État et du fournisseur de batterie virtuelle de votre projet solaire.",
    "prices-before-tax-disclaimer": "* Tarif en HT",
    points: {
      "edf-oa": {
        title: "EDF OA",
        description:
          "Vendez votre production excédentaire au réseau et bénéficiez d'une subvention de l'État.",
        first: "Garantie sur 20 ans",
        second: "Bénéficiez de l'aide de l’État",
        third: "Pas indexé sur l'inflation",
        fourth: "Assujetti à l'impôt sur le revenu",
      },
      "urban-solar-energy": {
        title: "Urban solar energy",
        description:
          "Stockez toute la production virtuellement pour la consommer et réaliser plus d'économies.",
        first: "Économies sur facture maximisées",
        second: "Gagner plus à chaque hausse des prix",
        third: "Frais de 200 € et taxe d'acheminement à payer",
        fourth: "Abonnement mensuel 1 € par kWc",
      },
      "mylight-systems": {
        title: "MyLight150",
        description:
          "Stockez toute la production virtuellement pour la consommer et réaliser plus d'économies.",
        first: "Économies sur facture maximisées",
        second: "Gagner plus à chaque hausse des prix",
        third: "Abonnement à partir de 15 € par mois",
        fourth: "Stockage par tranche",
      },
      jpme: {
        title: "JPME",
        description:
          "Stockez toute la production virtuellement pour la consommer et réaliser plus d'économies.",
        first: "Économies sur facture maximisées",
        second: "Gagner plus à chaque hausse des prix",
        third: "Frais d'inscription à partir de 649 €",
        fourth: "Régularisation qu'une fois par an",
      },
    },
  },
}
